<template>
  <div>
    <b-overlay :show="loading" no-wrap />
    <b-overlay :show="!cCanUploadOrEditWallpaper" rounded="sm">
      <template v-slot:overlay>
        <div class="d-flex align-items-center flex-column">
          <feather-icon icon="LockIcon" size="40" class="mb-50 text-danger" />
          <p class="m-0 text-danger">ไม่สามารถอัปโหลดรูปได้อีกในวันนี้ เพราะเกินโควต้าแล้ว</p>
        </div>
      </template>
      <form-wizard
        ref="ref-tab-upload-wallpaper"
        color="#7367F0"
        :title="null"
        :subtitle="null"
        shape="square"
        finish-button-text="บันทึก"
        back-button-text="ย้อนกลับ"
        next-button-text="ต่อไป"
        class="mb-3"
        lazy
        @on-complete="saveImageToServer"
        @on-change="onTabChange"
      >
        <div class="mb-1">
          <tab-translations-widget
            v-show="tabActive < 2"
            ref="refTabTranslations"
            @onChange="onTabTranslationsChange"
          />
        </div>

        <tab-content title="อัพโหลดรูป" lazy :before-change="ValidateStepUpload">
          <UploadWallpaperStepUpload :translationTabKey="keyTranslation" />
        </tab-content>
        <tab-content title="ตั้งค่ารายละเอียดรูปหน้าหลัก" lazy :before-change="validateFormSepTwo">
          <UploadWallpaperStepTwo ref="refUploadWallpaperStepTwo" :translationTabKey="keyTranslation" />
        </tab-content>
        <tab-content title="ตั้งค่าตำแหน่งรูปภาพ" lazy :before-change="getPositionsFromStepThree">
          <UploadWallpaperStepThree ref="refUploadWallpaperStepThree" :active="tabThreeActive" />
        </tab-content>
        <tab-content title="พรีวิว" lazy>
          <UploadWallpaperStepFour ref="refUploadWallpaperStepFour" :focus="tabActive === 3" />
        </tab-content>
      </form-wizard>
    </b-overlay>

    <!-- <pre>po {{ JSON.stringify(wallpaperDatas.mainImage.positionList, null, 2) }}</pre> -->
    <!-- <pre>de {{ JSON.stringify(wallpaperDatas.mainImage.defaultValuePositionList, null, 2) }}</pre> -->
    <!-- <pre>main {{ JSON.stringify(wallpaperDatas.mainImage, null, 2) }}</pre> -->
    <!-- <pre>all {{ JSON.stringify(wallpaperDatas.allImage, null, 2) }}</pre> -->
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { mapMutations, mapState } from 'vuex'
import UploadWallpaperStepUpload from './components/UploadWallpaperStepUpload.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UploadWallpaperStepTwo from './components/UploadWallpaperStepTwo.vue'
// import UploadWallpaperStepThree from './components/UploadWallpaperStepThree.vue'
import UploadWallpaperStepFour from './components/UploadWallpaperStepFour.vue'

export default {
  components: {
    FormWizard,
    TabContent,
    UploadWallpaperStepUpload,
    UploadWallpaperStepTwo,
    UploadWallpaperStepThree: () => import('./components/UploadWallpaperStepThree.vue'),
    UploadWallpaperStepFour,
  },
  data() {
    return {
      loading: false,
      tabThreeActive: false,
      tabActive: 0,
      keyTranslation: 'th',
    }
  },
  computed: {
    ...mapState('wallpapers', ['wallpaperDatas']),
    ...mapState('AuthStore', ['userInfo']),
    ...mapState('app-mobile-public-configs', ['langsSupport']),
    stepOptions() {
      return [
        {
          key: '',
          stepTitle: '',
          finishButtonText: '',
        },
      ]
    },
    cCanUploadOrEditWallpaper() {
      if (this.userInfo.role_data_preview === 'admin') return true

      if (this.wallpaperDatas.mainImage?.id) return true

      if (this.userInfo.amount_upload_per_day === 0) return true // เพราะเป็น no limit upload

      if (this.userInfo.upload_limit_used < this.userInfo.amount_upload_per_day) return true

      return false
    },
  },
  beforeDestroy() {
    setTimeout(() => {
      this.RESET_WALLPAPER_STATE()
    }, 100)
  },
  methods: {
    ...mapMutations('wallpapers', ['RESET_WALLPAPER_STATE', 'UPDATE_MAIN_POSITION_LIST']),
    ...mapMutations('AuthStore', ['UPDATE_LIMIT_UPLOAD_WALLPAPER']),
    onTabChange(_, nextIndex) {
      // console.log('nextIndex', nextIndex)
      this.tabActive = nextIndex
      if (nextIndex === 2) {
        setTimeout(() => {
          this.tabThreeActive = true
        }, 500)
      } else {
        this.tabThreeActive = false
      }

      if (nextIndex === 3) {
        const imageList = this.wallpaperDatas.allImage

        if (imageList && imageList.length > 0) {
          const { fontColor } = imageList[0].options

          this.$refs.refUploadWallpaperStepFour.fontColor = `${fontColor}`
        }
      }
    },
    ValidateStepUpload() {
      return new Promise((resolve, reject) => {
        const allImage = this.wallpaperDatas.allImage
        if (allImage.length <= 0) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'โปรดอัพโหลดรูปวอลเปเปอร์',
              icon: 'BellIcon',
              text: '',
              variant: 'danger',
            },
          })
          return resolve(false)
        }

        if (allImage.length > 0) {
          const firstImage = allImage[0]
          const { naturalWidth, naturalHeight } = firstImage.file
          const inValidDimension = allImage.some(
            val => val.file.naturalHeight !== naturalHeight && val.file.naturalWidth !== naturalWidth,
          )
          if (inValidDimension) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'โปรดเช็คขนาดรูปอีกครั้ง',
                icon: 'BellIcon',
                text: 'ขนาดรูปต้องเท่ากันทั้งหมด โดยอ้างอิงจากรูปแรกสุดเท่านั้น',
                variant: 'danger',
              },
            })
            return resolve(false)
          }

          // check all image title require
          const inValidTitleNull = allImage.some(val => !val.options.title_translations.th)
          // console.log('inValidTitleNull', inValidTitleNull)
          if (inValidTitleNull) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'โปรดระบุชื่อรูป',
                icon: 'BellIcon',
                text: 'โปรดระบุชื่อรูปวอลเปเปอร์ทั้งหมดของหมวดหมู่ภาษาไทย ที่ถูกอัพโหลด',
                variant: 'danger',
              },
            })
            return resolve(false)
          }
        }

        resolve(true)
      })
    },
    validateFormSepTwo() {
      return new Promise(async (resolve, reject) => {
        const validate = await this.$refs.refUploadWallpaperStepTwo.validateForm()
        if (!validate) return resolve(false)
        const optionsFonts = await this.$refs.refUploadWallpaperStepTwo.getOptionsFonts()
        this.$refs.refUploadWallpaperStepFour.updateOptionsFonts(optionsFonts)
        this.$refs.refUploadWallpaperStepFour.updateMainOptionsFont()
        resolve(true)
      })
    },
    getPositionsFromStepThree() {
      return new Promise(resolve => {
        const positions = this.$refs.refUploadWallpaperStepThree.$refs.refEditor.getPositions()

        this.wallpaperDatas.mainImage.defaultValuePositionList = positions.positions

        this.wallpaperDatas.mainImage.positionList = positions.positions

        if (!positions) return resolve(false)

        return resolve(true)
      })
    },
    async saveImageToServer() {
      // console.log('saveImageToServer', this.wallpaperDatas)
      // return

      this.loading = true

      // const allImage = this.wallpaperDatas.allImage
      const { mainImage } = this.wallpaperDatas

      mainImage.title = `${mainImage.title_translations.th}`
      mainImage.details = `${mainImage.details_translations.th}`
      mainImage.hint_modal_free_image = `${mainImage.hint_modal_free_image_translations.th}`

      const defaultKeysTranslationObj = {}

      this.langsSupport.forEach(v => {
        defaultKeysTranslationObj[v.local_code] = ''
      })

      mainImage.title_translations = { ...defaultKeysTranslationObj, ...mainImage.title_translations }
      mainImage.details_translations = { ...defaultKeysTranslationObj, ...mainImage.details_translations }
      mainImage.hint_modal_free_image_translations = {
        ...defaultKeysTranslationObj,
        ...mainImage.hint_modal_free_image_translations,
      }

      // console.log('saveImageToServer', mainImage)

      // return

      const topicImageData = await this.createOrUpdateTopicImageData(mainImage)
      // console.log('topicImageData', topicImageData)

      if (topicImageData.message) {
        this.gDisplayToast('พบข้อผิดพลาด', topicImageData.message, 'danger')
        this.loading = false
        this.$router.push({ name: 'wallpapers', replace: true })
        return
      }

      await this.createOrUpdateTopicImageListData(topicImageData, defaultKeysTranslationObj)
      this.RESET_WALLPAPER_STATE()
      this.$refs['ref-tab-upload-wallpaper'].reset()
      // title => ถ้าสร้างรูปให้ใช้  สร้างรูปสำเร็จแล้ว ถ้าแก้ไขรูปให้ใช้ แก้ไขข้อมูลรูปสำเร็จแล้ว
      if (mainImage.id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'แก้ไขข้อมูลรูปสำเร็จแล้ว',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      } else {
        this.UPDATE_LIMIT_UPLOAD_WALLPAPER()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'สร้างรูปสำเร็จแล้ว',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      }
      this.loading = false
      this.$router.push({ name: 'wallpapers', replace: true })
    },
    async createOrUpdateTopicImageListData(topicImageData, defaultKeysTranslationObj = {}) {
      // forEach await ไม่โอเค ref: https://zellwk.com/blog/async-await-in-loops/
      // this.wallpaperDatas.allImage.forEach(async data => {
      //   const form = new FormData()
      //   form.append('option', Buffer.from(JSON.stringify(data.options)).toString('base64'))
      //   form.append('topic_image_data_id', topicImageData.id)
      //   form.append('file', data.file)
      //   form.append('id', data.id ?? '')
      //   await this.api.post('/api/admin/admin-topic-image-lists', form, false)
      // })

      const allImageTemp = [...this.wallpaperDatas.allImage]
      for (let index = 0; index < allImageTemp.length; index += 1) {
        const el = allImageTemp[index]
        el.options.title = `${el.options.title_translations.th}`
        el.options.detail = `${el.options.details_translations.th}`
        el.options.title_translations = { ...defaultKeysTranslationObj, ...el.options.title_translations }
        el.options.details_translations = { ...defaultKeysTranslationObj, ...el.options.details_translations }
        // console.log('createOrUpdateTopicImageListData el', el)

        const form = new FormData()
        form.append('option', Buffer.from(JSON.stringify(el.options)).toString('base64'))
        form.append('topic_image_data_id', topicImageData.id)
        form.append('file', el.file)
        form.append('id', el.id ?? '')
        // eslint-disable-next-line no-await-in-loop
        await this.api.post('/api/admin/admin-topic-image-lists', form, false)
      }
    },

    async createOrUpdateTopicImageData(mainImage) {
      // console.log('createOrUpdateTopicImageData mainImage', mainImage)
      const form = new FormData()
      form.append('id', mainImage.id ?? '')
      form.append('change_topic_image_id', mainImage?.changeId || '')
      form.append('file', mainImage.file)
      form.append('zodiac', mainImage.zodiac?.id)
      form.append('mainImage', Buffer.from(JSON.stringify(mainImage)).toString('base64'))
      const resp = await this.api.createOrUpdate('/api/admin/admin-topic-images', form, false)

      return resp
    },

    onTabTranslationsChange(values) {
      // console.log('onTabTranslationsChange', values)
      this.keyTranslation = values.local_code
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
