<template>
  <div class="">
    <b-overlay :show="loading" no-wrap />
    <validation-observer ref="form">
      <b-form>
        <b-row cols="1">
          <b-col>
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="title"
                :rules="translationTabKey === 'th' ? 'required' : ''"
              >
                <label for="title">ชื่อรูปวอลเปเปอร์</label>
                <small v-if="translationTabKey === 'th'" class="text-danger"> *จำเป็นต้องระบุ</small>
                <small v-else> (ถ้าไม่ระบุ จะแปลให้โดยอัตโนมัติ)</small>
                <b-form-input
                  id="title"
                  v-model="wallpaperDatas.mainImage.title_translations[translationTabKey]"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  placeholder="ระบุชื่อรูปวอลเปเปอร์"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="details"
                :rules="translationTabKey === 'th' ? 'required' : ''"
              >
                <label for="details">รายละเอียดวอลเปเปอร์</label>
                <small v-if="translationTabKey === 'th'" class="text-danger"> *จำเป็นต้องระบุ</small>
                <small v-else> (ถ้าไม่ระบุ จะแปลให้โดยอัตโนมัติ)</small>
                <b-form-textarea
                  id="details"
                  v-model="wallpaperDatas.mainImage.details_translations[translationTabKey]"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  placeholder="ระบุรายละเอียดวอลเปเปอร์"
                  rows="4"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group>
              <validation-provider #default="{ errors }" name="hint_modal_free_image">
                <label for="hint_modal_free_image">เงื่อนไขการใช้วอลเปเปอร์ฟรี (ไม่จำเป็น)</label>
                <small v-if="translationTabKey !== 'th'"> (ถ้าไม่ระบุ จะแปลให้โดยอัตโนมัติ)</small>
                <b-form-textarea
                  id="hint_modal_free_image"
                  v-model="wallpaperDatas.mainImage.hint_modal_free_image_translations[translationTabKey]"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  rows="2"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col v-if="$store.getters['AuthStore/_isCanManagementRedeem']">
            <b-form-group label="รูปแบบการขาย" label-for="hide_in_store">
              <div class="px-1">
                <b-form-radio
                  v-model="wallpaperDatas.mainImage.hide_in_store"
                  name="hide_in_store"
                  :value="false"
                  class="mb-50"
                >
                  แสดงในแอปและเว็บ <small>(สามารถใช้ Redeem หรือ Coin เพื่อสร้างรูปวอลเปเปอร์ได้)</small>
                </b-form-radio>
                <b-form-radio v-model="wallpaperDatas.mainImage.hide_in_store" name="hide_in_store" :value="true">
                  ไม่แสดงในแอปและเว็บ <small>(สามารถใช้ Redeem เพื่อสร้างรูปวอลเปเปอร์ได้เท่านั้น)</small>
                </b-form-radio>
              </div>
            </b-form-group>
          </b-col>

          <!-- selected multiple tag -->
          <b-col sm="12">
            <validation-provider #default="{ errors }" name="wallpaper_tags" rules="required">
              <b-form-group :state="errors.length > 0 ? false : null">
                <label for="wallpaper_tags">หมวดหมู่วอลเปเปอร์</label>
                <small class="text-danger"> *จำเป็นต้องระบุ</small>
                <v-select
                  v-model="wallpaperDatas.mainImage.wallpaper_tags"
                  label="tag_name"
                  multiple
                  :options="optionsTags"
                  :loading="optionsLoading"
                  :selectable="() => wallpaperDatas.mainImage.wallpaper_tags.length < 5"
                  :close-on-select="false"
                >
                  <template #spinner="{ loading: spinLoad }">
                    <div v-if="spinLoad">
                      <b-spinner small variant="primary" />
                    </div>
                  </template>
                </v-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col sm="12">
            <b-form-group label="ลิงค์ Youtube (ไม่จำเป็น)" label-for="youtube_link">
              <validation-provider #default="{ errors }" name="youtube_link" rules="url">
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="YoutubeIcon" class="text-danger" />
                  </b-input-group-prepend>
                  <b-form-input v-model="wallpaperDatas.mainImage.youtube_link" type="text" />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group>
              <label for="blog_link">
                ลิงค์ เนื้อหาเพิ่มเติมของวอลเปเปอร์ (ไม่จำเป็น)
                <a href="https://lin.ee/t3FGSRx" target="_blank" class="d-block d-sm-inline">
                  <u>ขอลงบทความในเว็บ ongphrawallpaper.com</u>
                </a>
              </label>
              <validation-provider #default="{ errors }" name="blog_link" rules="url">
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="GlobeIcon" class="text-primary" />
                  </b-input-group-prepend>
                  <b-form-input v-model="wallpaperDatas.mainImage.blog_link" type="text" />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="12">
            <b-form-group label="ราศี" label-for="zodiac">
              <v-select
                v-model="wallpaperDatas.mainImage.zodiac"
                label="name"
                :options="options"
                :disabled="options.length <= 0"
                :loading="optionsLoading"
              >
                <template #spinner="{ loading: spinLoad }">
                  <div v-if="spinLoad">
                    <b-spinner small variant="primary" />
                  </div>
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <!-- select font TH -->
          <b-col sm="12" md="6">
            <b-form-group label="เลือกฟ้อน TH" label-for="fontFamilyTH">
              <v-select
                v-model="wallpaperDatas.mainImage.optionsFont.TH.name"
                :options="optionsFontNameTH"
                label="family"
                :style="{ 'font-family': _fontActiveTH }"
              >
                <template v-slot:option="option">
                  <span :class="option.icon"></span>
                  <div :style="{ 'font-family': option.family }">{{ option.family }}</div>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6">
            <b-form-group label="เลือกสไตล์ฟ้อน TH" label-for="fontStyleTH">
              <v-select
                v-model="wallpaperDatas.mainImage.optionsFont.TH.style"
                label="font"
                :options="wallpaperDatas.mainImage.optionsFont.TH.options"
                :getOptionLabel="getOptionLabelStyleFont"
                :disabled="
                  wallpaperDatas.mainImage.optionsFont.TH.name === null ||
                    wallpaperDatas.mainImage.optionsFont.TH.options.length <= 0
                "
                :clearable="false"
              />
            </b-form-group>
          </b-col>

          <!-- select font EN -->
          <b-col sm="12" md="6">
            <b-form-group label="เลือกฟ้อน EN" label-for="fontFamilyEN">
              <v-select
                v-model="wallpaperDatas.mainImage.optionsFont.EN.name"
                :options="paginated"
                label="family"
                @open="onOpen"
                @close="onClose"
                @search="query => (search = query)"
                :style="{ 'font-family': _fontActiveEN }"
              >
                <template v-slot:option="option">
                  <span :class="option.icon"></span>
                  <div :style="{ 'font-family': option.family }">{{ option.family }}</div>
                </template>
                <template #list-footer>
                  <li v-show="hasNextPage" ref="load" class="loader">
                    <div class="d-flex justify-content-center mb-1 loader">
                      <b-spinner type="grow" variant="primary" />
                    </div>
                  </li>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6">
            <b-form-group label="เลือกสไตล์ฟ้อน EN" label-for="fontStyleEN">
              <v-select
                v-model="wallpaperDatas.mainImage.optionsFont.EN.style"
                label="font"
                :options="wallpaperDatas.mainImage.optionsFont.EN.options"
                :getOptionLabel="getOptionLabelStyleFont"
                :disabled="
                  wallpaperDatas.mainImage.optionsFont.EN.name === null ||
                    wallpaperDatas.mainImage.optionsFont.EN.options.length <= 0
                "
                :clearable="false"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapState } from 'vuex'
import { required, numeric, double } from '@validations'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    translationTabKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      options: [],
      optionsFontNameTH: [],
      optionsFontNameEN: [],
      optionsTags: [],
      localActiveFontTH: null,
      localActiveFontEN: null,
      loading: false,
      observer: null,
      limit: 20,
      search: '',
      optionsLoading: false,
    }
  },
  async mounted() {
    const { role_data_preview } = this.userInfo
    let url = 'api/partner/manage-images/fetch/zodiac-with-approved'
    if (role_data_preview === 'admin') {
      url = '/api/admin/admin-topic-images?filterType=zodiac&whereInUser=true'
    }
    this.optionsLoading = true
    this.options = await this.api.get(url).catch(() => []) // ปรับถ้า api ตัวนี้แตกแล้วจะไม่ไปดึงฟ้อน
    this.optionsTags = await this.api.get('api/topic-image-tags/fetch').catch(() => []) // โหลดหมวดหมู่วอลเปเปอร์
    this.optionsLoading = false
    // console.log(this.userInfo)
    const res = await this.api.get('api/customers/google-font/fetch')
    this.observer = new IntersectionObserver(this.infiniteScroll)
    // console.log('res', res)
    const { eng, thai } = JSON.parse(res)
    // console.log(thai)
    this.optionsFontNameTH = thai
    this.optionsFontNameEN = eng

    this.loadGoogleFontSrc([...thai])
  },
  computed: {
    ...mapState('wallpapers', ['wallpaperDatas']),
    ...mapState('AuthStore', ['userInfo']),

    filtered() {
      return this.optionsFontNameEN.filter(en => en.family.includes(this.search))
    },
    paginated() {
      return this.filtered.slice(0, this.limit)
    },
    hasNextPage() {
      return this.paginated.length < this.filtered.length
    },
    _fontActiveTH() {
      return this.wallpaperDatas.mainImage.optionsFont.TH.name?.family ?? null
    },
    _fontActiveEN() {
      return this.wallpaperDatas.mainImage.optionsFont.EN.name?.family ?? null
    },
  },
  methods: {
    async validateForm() {
      const validate = await this.$refs.form.validate()
      if (!validate) {
        this.gDisplayToast('โปรดระบุข้อมูลให้ครบถ้วน', '', 'danger')
        return false
      }

      if (
        !this.wallpaperDatas.mainImage.title_translations.th ||
        !this.wallpaperDatas.mainImage.details_translations.th
      ) {
        this.gDisplayToast(
          'โปรดระบุข้อมูลให้ครบถ้วน',
          'โปรดระบุชื่อรูป รายละเอียดรูปวอลเปเปอร์ หมวดหมู่ภาษาไทย',
          'danger',
        )
        return false
      }

      return true
    },
    async fetchGoogleFont(key) {
      if (!key) return

      this.wallpaperDatas.mainImage.optionsFont[key].style = null
      // this.wallpaperDatas.mainImage.optionsFont[key].options = null
      const fontName = this.wallpaperDatas.mainImage.optionsFont[key].name.family

      if (!fontName) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `โปรดระบุชื่อฟ้อน ${key}`,
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
        return
      }
      this.loading = true

      const res = await this.api.post(
        'api/customers/google-font',
        {
          fontname: fontName,
        },
        false,
      )
      // console.log(`[RES]: fetchGoogleFont key = ${key}`, res)

      if (typeof res === 'string') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `ไม่พบฟ้อน ${key} ที่ชื่อ ${fontName}`,
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      } else {
        this.wallpaperDatas.mainImage.optionsFont[key].options = res
        this.wallpaperDatas.mainImage.optionsFont[key].style = res.length > 0 ? { ...res[0] } : null
        if (res.length <= 0) {
          this.gDisplayToast(
            `ไม่พบสไตล์ฟ้อน ${fontName}`,
            'โปรดเลือกฟ้อนอื่น หรือไม่ระบบจะใช้ default ฟ้อนแทน',
            'danger',
          )
        }
      }
      this.loading = false
    },
    getOptionLabelStyleFont(option) {
      // console.log('option', option)
      const { font } = option
      return `${font}`.split('.')[0]
    },
    getFourVariants(variants) {
      const regularWeights = [...variants]
        .filter(v => Boolean(Number(v)))
        .map(v => `0,${v}`)
        .sort((a, b) => a - b)
      const italicWeights = [...variants]
        .filter(v => v.slice(3) === 'italic')
        .map(v => `1,${v.slice(0, 3)}`)
        .sort((a, b) => a - b)

      // console.log('regularWeights', regularWeights)
      // console.log('italicWeights', italicWeights)
      // return `${regularWeights.join(';')};${italicWeights.join(';')}`
      // fourFont.regular = regularWeights.
    },
    loadGoogleFontSrc(allFont) {
      // console.log('load', [...allFont].length)
      // <link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet">
      for (let i = 0; i < allFont.length; i += 1) {
        const { family, variants } = allFont[i]
        const fontFormat = `${family}`.split(' ').join('+')
        const cssId = `google-font-${fontFormat}-all`
        const existingFontLoad = document.getElementById(cssId)
        if (existingFontLoad) {
          return
        }
        // console.log('load font', allFont)
        // console.log(fontFormat)
        // const fontWeight = this.getFourVariants(variants)
        // console.log('fontWeight', fontWeight)
        const link = document.createElement('link')
        link.rel = 'stylesheet'
        link.id = cssId
        link.href = `https://fonts.googleapis.com/css2?family=${fontFormat}&display=swap`
        document.getElementsByTagName('head')[0].appendChild(link)
      }
    },
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose() {
      this.observer.disconnect()
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.limit += 20
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
    getOptionsFonts() {
      return { optionsFontNameTH: this.optionsFontNameTH, optionsFontNameEN: this.optionsFontNameEN }
    },
  },
  watch: {
    'wallpaperDatas.mainImage.optionsFont.TH.name': function(newValue) {
      // console.log(newValue)

      if (newValue === null) {
        this.wallpaperDatas.mainImage.optionsFont.TH.style = null
        this.wallpaperDatas.mainImage.optionsFont.TH.options = []
        return
      }
      this.fetchGoogleFont('TH')
    },
    'wallpaperDatas.mainImage.optionsFont.EN.name': function(newValue) {
      // console.log(newValue)
      if (newValue === null) {
        this.wallpaperDatas.mainImage.optionsFont.EN.style = null
        this.wallpaperDatas.mainImage.optionsFont.TH.options = []
        return
      }
      this.fetchGoogleFont('EN')
    },
    paginated(newValue) {
      const numSlice = this.limit - 20
      const listForLoad = [...newValue].slice(numSlice <= 0 ? 0 : numSlice)

      if (listForLoad.length <= 0) return
      this.loadGoogleFontSrc(listForLoad)
    },
    filtered() {
      // console.log(this.limit)
      if (this.limit > 20) {
        this.limit = 20
      }
    },
  },
}
</script>
